.project_container {
    width: 550px;
}
.project_card {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem 1rem;
    border-radius: 1.5rem;
    margin-bottom: var(--mb-3);
}
.project_img {
    width: 600px;
    border-radius: 1rem;
    margin-bottom: var(--mb-1);
}
.project_name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-25);
}
.project_description {
    font-size: var(--small-font-size);
}


.swiper-pagination-bullet {
    background-color: var(--text-color) !important;
}
.swiper-pagination-bullet-active {
    background-color: var(--title-color) !important;
}



@media screen and (max-width: 992px){
    .project_container {
       width: initial;
    }
    .project_card {
        padding: 1.25rem 1.5rem;
    }
   }
